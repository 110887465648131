<template>
  <div>
    <b-card>
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Başlangıç Tarihi"
            label-for="sdate"
          >
            <b-form-datepicker
              id="sdate"
              v-model="sdate"
              v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
              locale="tr"
              start-weekday="1"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Bitiş Tarihi"
            label-for="edate"
          >
            <b-form-datepicker
              id="edate"
              v-model="edate"
              v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
              locale="tr"
              start-weekday="1"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
        >
          <b-form-group
            label="Başvuru Durumu"
            label-for="id_com_crm_status"
          >
            <v-select
              id="id_com_crm_status"
              v-model="id_com_crm_status"
              label="title"
              :options="crmStatuses"
              placeholder="Seçiniz"
              :close-on-select="false"
              :reduce="crmStatus => crmStatus.id"
              multiple
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
        >
          <b-form-group
            label="Departman"
            label-for="id_com_department"
          >
            <v-select
              id="id_com_department"
              v-model="id_com_department"
              label="title"
              :options="departments"
              placeholder="Seçiniz"
              :close-on-select="false"
              :reduce="department => department.id"
              multiple
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
        >
          <b-form-group
            label="Başvuru Konusu"
            label-for="id_com_crm_subject"
          >
            <v-select
              id="id_com_crm_subject"
              v-model="id_com_crm_subject"
              label="title"
              :options="crmSubjects"
              placeholder="Seçiniz"
              :close-on-select="false"
              :reduce="crmSubject => crmSubject.id"
              multiple
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          class="text-center"
        >
          <b-button
            variant="success"
            :disabled="!sdate || !edate"
            :href="downloadURL"
            target="_blank"
          >
            <FeatherIcon icon="DownloadIcon" />
            Excel'e Aktar
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormDatepicker, BFormGroup, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'RequestsExcel',
  components: {
    BCard,
    BRow,
    BCol,
    BFormDatepicker,
    BFormGroup,
    BButton,
    vSelect,
  },
  data() {
    return {
      sdate: null,
      edate: null,
      id_com_crm_status: [],
      id_com_department: [],
      id_com_crm_subject: [],
    }
  },
  computed: {
    crmStatuses() {
      return this.$store.getters['crmStatuses/getCrm_statuses']
    },
    crmSubjects() {
      return this.$store.getters['crmSubjects/getCrm_subjects']
    },
    departments() {
      return this.$store.getters['departments/getDepartments']
    },
    downloadURL() {
      const urlParams = []
      if (this.sdate) {
        urlParams.push(`sdate=${this.sdate}`)
      }
      if (this.edate) {
        urlParams.push(`edate=${this.edate}`)
      }
      if (this.id_com_crm_status) {
        this.id_com_crm_status.forEach(e => {
          urlParams.push(`id_com_crm_status[]=${e}`)
        })
      }
      if (this.id_com_crm_subject) {
        this.id_com_crm_subject.forEach(e => {
          urlParams.push(`id_com_crm_subject[]=${e}`)
        })
      }
      if (this.id_com_department) {
        this.id_com_department.forEach(e => {
          urlParams.push(`id_com_department[]=${e}`)
        })
      }
      const params = urlParams.join('&')
      let url = `${this.$store.state.app.baseURL}/exports/data/crm_export/excel?token=${localStorage.getItem('downloadToken')}`
      if (params) {
        url += `&${params}`
      }
      return url
    },
  },
  created() {
    this.getStatuses()
    this.getDepartments()
    this.getCrmSubjects()
  },
  methods: {
    getStatuses() {
      this.$store.dispatch('crmStatuses/crm_statusesList', {
        select: [
          'com_crm_status.id AS id',
          'com_crm_status.title AS title',
        ],
      })
    },
    getDepartments() {
      this.$store.dispatch('departments/departmentsList', {
        select: [
          'com_department.id AS id',
          'com_department.title AS title',
        ],
        where: {
          type: 1,
        },
      })
    },
    getCrmSubjects() {
      this.$store.dispatch('crmSubjects/crm_subjectsList', {
        select: ['com_crm_subject.id AS id', 'com_crm_subject.title AS title'],
      })
    },
  },
}
</script>
